<template>
  <nav class="bg-green-600">
    <div class="flex items-center justify-between pl-10 pr-10 pt-3 pb-3">
      <div class="text-5xl bbg-blue-400 text-white">Em Zak</div>

      <div class="bbg-red-400 w-1/3 flex justify-between">
        <router-link to="/" class="text-2xl text-white">Home</router-link>
        <router-link to="/about" class="text-2xl text-white">About</router-link>
        <router-link to="/projects" class="text-2xl text-white"
          >Projects</router-link
        >
      </div>
    </div>
  </nav>

  <div class="flex justify-center p-20">
    <img class="h-96" src="logo.svg" alt="" />
  </div>

  <!-- <router-view/> -->
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
